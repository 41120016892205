import dayjs from "dayjs";

const useFunrizeDepositStreakData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const isGuest = useIsGuest();
	const { data: appInit } = useAppInitData();
	const { refresh: refreshHomePage } = useHomeData({ immediate: false });
	const { refresh: refreshPromotionsData } = usePromotionsPage({ immediate: false });
	const { data: depositInfoData, refresh } = useGetDepositStreakData({ immediate });

	const timeRemaining = computed(() =>
		dayjs(depositInfoData.value.finishedAt).diff(dayjs(), "d") !== 0 ? "DD[ d ]HH[ h ]mm[ m]" : "HH[ h ]mm[ m ]ss[ s]"
	);

	const { durationLeft, reset, durationExceeded } = useCountdown({
		timestamp: depositInfoData.value.finishedAt || "",
		formatToken: timeRemaining.value,
		onCountdownStop: () => {
			if (appInit.value?.depositStreak?.isActive && !isGuest.value) {
				appInit.value.depositStreak.isActive = false;
				refreshHomePage();
				refreshPromotionsData();
			}
		}
	});

	const { durationLeft: durationLeftResetStreak, reset: resetDurationStreak } = useCountdown({
		timestamp: depositInfoData.value?.resetStreakAt || "",
		formatToken: "HH[ h ]mm[ m ]ss[ s]",
		onCountdownStop: !isGuest.value ? refresh : () => {}
	});

	watch(
		() => depositInfoData.value.finishedAt,
		(value) => {
			reset(value || "");
		}
	);

	watch(
		() => depositInfoData.value.resetStreakAt,
		(value) => {
			resetDurationStreak(value || "");
		}
	);

	return { depositInfoData, refresh, durationLeft, durationExceeded, durationLeftResetStreak };
};

export default useFunrizeDepositStreakData;
